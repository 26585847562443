.Notes form {
  padding-bottom: 15px;
}

.Notes form textarea {
  height: 300px;
  font-size: 18px;
}

.LoaderButton:focus {
  outline: none !important;
  outline-offset: none !important;
}