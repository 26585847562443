.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
}

.sign-in-tile {
  vertical-align: middle;
}