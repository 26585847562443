.refresh-spin {
  vertical-align: bottom;
  /* margin-right: 4px; */
  animation: refresh-spin .5s infinite linear;
}
@keyframes refresh-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}